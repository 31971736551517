import {mapGetters} from 'vuex';

export default {
	name: 'Rating',
	i18n: require('./i18n').default,
	data: function () {
		return {
			needsName: false,
			needsSn: false,
			needsPhoto: false,
		};
	},
	init({store, context}) {
		let cookies = {};
		if (context) {
			cookies = context.request.headers.cookie;
		}
		return Promise.all([
			store.dispatch('cabinet/rating/rating', cookies),
			store.dispatch('cabinet/rating/ratingState', cookies)
		]);
	},
	created() {
		if (this.ratingState.length) {
			this.needsName = this.ratingState.indexOf(1) > -1;
			this.needsSn = this.ratingState.indexOf(3) > -1;
			this.needsPhoto = this.ratingState.indexOf(5) > -1;
		}
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
			rating: 'cabinet/rating/rating',
			ratingState: 'cabinet/rating/ratingState',
			userInfo: 'cabinet/userInfo/userData'
		})
	}
};
