export default {
	messages: {
		ru: {
			'Рейтинг пользователя': 'Рейтинг пользователя',
			'Продавец': 'Продавец',
			'Ваш рейтинг пользователя': 'Ваш рейтинг пользователя',
			'низкий': 'низкий',
			'средний': 'средний',
			'высокий': 'высокий',
			'максимальный': 'максимальный',
			'из': 'из',
			'Рейтинг отображает вашу активность, открытость и степень проверености.': 'Рейтинг отображает вашу активность, открытость и степень проверености.',
			'Спасибо за доверие AUTO.RIA': 'Спасибо за доверие AUTO.RIA',
			'Дополните информацию о себе и поднимите свой рейтинг': 'Дополните информацию о себе и поднимите свой рейтинг',
			'Укажите имя': 'Укажите имя',
			'Имя указано': 'Имя указано',
			'Загрузите своё фото': 'Загрузите своё фото',
			'Фото загружено': 'Фото загружено',
			'Добавьте профиль facebook или google+': 'Добавьте профиль facebook или google+',
			'Профиль соцсети добавлен': 'Профиль соцсети добавлен',
			'Редактировать профиль': 'Редактировать профиль'
		},
		uk: {
			'Рейтинг пользователя': 'Рейтинг користувача',
			'Продавец': 'Продавець',
			'Ваш рейтинг пользователя': 'Ваш рейтинг користувача',
			'низкий': 'низький',
			'средний': 'середній',
			'высокий': 'високий',
			'максимальный': 'максимальний',
			'из': 'з',
			'Рейтинг отображает вашу активность, открытость и степень проверености.': 'Рейтинг відображає вашу активність, відкритість і ступінь перевіреності.',
			'Спасибо за доверие AUTO.RIA': 'Дякуємо за довіру AUTO.RIA',
			'Дополните информацию о себе и поднимите свой рейтинг': 'Доповніть інформацію про себе і підніміть свій рейтинг',
			'Укажите имя': 'Вкажіть імʼя',
			'Имя указано': 'Iмʼя вказано',
			'Загрузите своё фото': 'Завантажте своє фото',
			'Фото загружено': 'Фото завантажене',
			'Добавьте профиль facebook или google+': 'Додайте профіль facebook або google+',
			'Профиль соцсети добавлен': 'Профіль соцмережі доданий',
			'Редактировать профиль': 'Редагувати профіль'
		}
	}
};
